$(document).on('turbolinks:load', function () {
    var con_url = $("#con_url").val()
    var con_id = $("#conversation_id").val()

    $(".chat-content-scroll").data('ajaxready', true);
    $(".chat-content-scroll").on('scroll', function (e) {
        checkForChatcrollEvent($(this));
    });
    if (typeof con_url !== 'undefined') {
        $.getScript(con_url, function (data, textStatus, jqxhr) {
            $("#message-" + con_id).scrollTop($("#message-" + con_id)[0].scrollHeight);
            listenForChatScrollEvent($(".chat-content-scroll"));
        });
    }
    var listenForChatScrollEvent = function (el) {
        el.on('scroll', function () {
            checkForChatcrollEvent($(this));
        });
    };

    var checkForChatcrollEvent = function (el) {
        var more_users_url;
        more_users_url = void 0;
        if ($(".chat-content-scroll").data('ajaxready') === false) {
            return;
        }
        more_users_url = $('#all-infinite-scrolling-msg .pagination a[rel=next]').attr('href');
        if (more_users_url && el.scrollTop() === 0) {
            $('#loading').removeClass('hidden');
            $(".chat-content-scroll").data('ajaxready', false);
            $.getScript(more_users_url + "&prev_date=" + $("#prev_date").val());
        }
    };
});