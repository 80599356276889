$(document).on('turbolinks:load', function () {
    new List('course-category-teacher', {valueNames: ['category_name']});
    new List('course-teacher-search', {valueNames: ['teacher_name']});
});

$(document).on('change', '.category-item-1, .teacher-item-1, .sorting-course-1', function () {
    search_form()
});

export function search_form() {
    $("body").addClass('white-overlay');
    $.ajax({
        url: '/student_courses',
        type: 'GET',
        dataType: 'script',
        data: $("#filter_form").serializeArray(),
        success: function (result) {}
    });
}
