import HSStepForm from "./step-form";

$(document).on('turbolinks:load', function () {
    initialize_signup_form();
    var serviceForm = $("#newServiceForm");
    var nextStep = true;

    new HSStepForm($('.service-step-form'), {
        isValidate: true,
        finish: function () {
            return sendServiceForm("last_step");
            // serviceForm.submit();
        },
        onStepChanging: function (el, params, nextEl) {

            if (serviceForm.valid()) {
                return sendServiceForm("step");
            } else {
                return false;
            }
        },
        onNextStep: function(el, params, nextEl) {
            var nextDataSettings = nextEl.attr('data-hs-step-form-next-options') ? JSON.parse(nextEl.attr('data-hs-step-form-next-options')) : {};
            if(nextDataSettings["targetSelector"] == "#photoInformation"){
                    init_filepond_media()
            }
            scrollToTop('.service-step-form')
        },
        onPrevStep: function () {
            scrollToTop('.service-step-form')
        }
    }).init();
});

function scrollToTop(el) {
    $('html, body').animate({
        scrollTop: $(el).offset().top - 60
    }, 500)
}

function sendServiceForm(step) {
    var serviceFormObj = $("#newServiceForm")
    var flag = false;
    $.ajax({
        url: serviceFormObj.attr('action'),
        type: serviceFormObj.attr('method'),
        dataType: "script",
        async: false,
        data: serviceFormObj.serialize()+"&step="+step,
        success: function (response) {
            if (step == "last_step"){
                $("#publishServiceModal").removeClass('d-none');
                $("#publishServiceModal").modal('show');
            }
            flag = true
        },
        error: function (response) {
            flag = false
        }
    });
    return flag;
}

export function initialize_signup_form() {
    var cid = $('#tutor_service_id').val()
    var formService = $("#newServiceForm").validate({
        rules: {
            "tutor_service[description]": {
                required: function () {
                    CKEDITOR.instances.tutor_service_description.updateElement();
                },
                minlength: 10
            },

            "tutor_service[max_age]": {
                min: function () {
                    return parseInt($('#tutor_service_min_age').val());
                }
            },
            "tutor_service[max_capacity]": {
                min: function () {
                    return parseInt($('#tutor_service_min_capacity').val());
                }
            },
        },
        errorPlacement: function (error, element) {
            if (element.hasClass('mobile-number')) {
                element.closest(".iti").parent().append(error);
            }
            else {
                element.closest("div").append(error);
            }

    }
    });
}
