import {Calendar} from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";

export function getCalendar(url, event_url) {
    if ($('#full-calendar').length != 0) {
        var calendarEl = document.getElementById('full-calendar');
        var calendar = new Calendar(calendarEl, {
            plugins: [dayGridPlugin, listPlugin],
            initialView: 'dayGridMonth',
            headerToolbar: {
                start: 'prev,next today',
                center: 'title',
                // end: 'dayGridMonth,dayGridWeek'
                end: 'dayGridMonth,dayGridWeek,dayGridDay,list'
            },
            buttonText: {
                today: 'Today',
                month: 'Month',
                week: 'Week',
                day: 'Day',
                list: 'List'
            },
            events: event_url,
            eventClick: function (info) {
                $.ajax({
                    type: "get",
                    dataType: 'script',
                    url: url + '/' + info.event.id,
                    success: function (rst) {
                    }
                });
                $(this).css('border-color', 'orange');
            },
            editable: true,
            selectable: true,
            // eventDisplay: function (event, element) {
            //     console.log("dasf")
            //     const title = $(event.el).find('.fc-title');
            //     title.html(title.text());
            //
            // },
            eventDidMount: function (info) {
                if (info.view.type != "list") {
                    info.el.querySelector('.fc-event-title').innerHTML = "<span style='display: flex;  justify-content: space-between;  flex-direction: column;  text-align: center;overflow-wrap:break-word !important;'>" + info.event.title + "</span>" + "<span>" + info.event.extendedProps.time + "</span>";
                }
            },
            droppable: false
        });
        calendar.render();
    }
}



