import HSStepForm from "./step-form";

$(document).on('turbolinks:load', function () {
    initialize_signup_form();
    var courseForm = $("#newCourseForm");
    var nextStep = true;

    new HSStepForm($('.course-step-form'), {
        isValidate: true,
        finish: function () {
            return sendCourseForm("last_step");
            // courseForm.submit();
        },
        onStepChanging: function (el, params, nextEl) {
            $('.sessions_error').html('');
            if ($(".course-session-fields .sessions span").length != 0) {
                $(".course-session-fields").find('input').removeAttr('required');
            }
            else {
                $(".course-session-fields").find('input').attr('required', 'required');
            }

            var nextDataSettings = nextEl.attr('data-hs-step-form-next-options') ? JSON.parse(nextEl.attr('data-hs-step-form-next-options')) : {};
            if (nextDataSettings["targetSelector"] == "#courseDescription") {
                if ($(".course-session-fields .sessions span").length == 0) {
                    $('.course-session-fields [type=text]').each(function () {
                        nextStep = courseForm.validate().element(this);
                    });
                    if (nextStep) {
                        $('.sessions_error').html('Please Click On Add Button to add date & time');
                        nextStep = false;
                    }
                    else {
                        $('.sessions_error').html('')
                    }
                }
                else {
                    nextStep = true
                }
            }
            else {
                nextStep = true
            }
            $("#course_course_session").removeData("previousValue");
            if (courseForm.valid() && nextStep) {
                return sendCourseForm("step");
            } else {
                return false;
            }
        },
        onNextStep: function(el, params, nextEl) {
            var nextDataSettings = nextEl.attr('data-hs-step-form-next-options') ? JSON.parse(nextEl.attr('data-hs-step-form-next-options')) : {};
            if(nextDataSettings["targetSelector"] == "#photoInformation"){
                    init_filepond_media()
            }
            scrollToTop('.course-step-form')
        },
        onPrevStep: function () {
            scrollToTop('.course-step-form')
        }
    }).init();

    $("#course-type-btns :input").change(function () {
        if ($(this).val() == 'one_time') {
            $('#course_course_session,#course_course_week').attr('disabled', 'disabled')
        } else {
            $('#course_course_session,#course_course_week').removeAttr('disabled')
        }
    });

    courseForm.find('#course-session-datetime').on('click', function () {
        $(".course-session-fields").find('input').attr('required', 'required');
        var flag = false
        $('.course-session-fields [type=text]').each(function () {
            flag = courseForm.validate().element(this);
        });
        if (flag) {
            $.ajax({
                url: '/teachers/sessions/' + $(this).attr('data-course-id') + '/add_session/',
                type: 'post',
                dataType: "script",
                data: {
                    course_session: {
                        session_date: $(".course-session-fields").find('#date').val(),
                        start_time: $(".course-session-fields").find('#start_time').val(),
                        end_time: $(".course-session-fields").find('#end_time').val()
                    }
                },
                success: function (response) {

                },
                error: function (response) {

                }
            });
        }

    });
    $("#course_course_session").change(function () {
        $('.sessions_error').html('')
    });
});

function scrollToTop(el) {
    $('html, body').animate({
        scrollTop: $(el).offset().top - 60
    }, 500)
}

function sendCourseForm(step) {
    var courseFormObj = $("#newCourseForm")
    var flag = false;
    if (step == "last_step") {
        courseFormObj.find(".postcoursefinishBtn").html(
            `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...`
        );
    }
    $.ajax({
        url: courseFormObj.attr('action'),
        type: courseFormObj.attr('method'),
        dataType: "script",
        async: false,
        data: courseFormObj.serialize()+"&step="+step,
        success: function (response) {
            if (step == "last_step"){
                courseFormObj.find(".postcoursefinishBtn").html("Save")
                $("#publishModal").removeClass('d-none');
                $("#publishModal").modal('show');
            }
            flag = true
        },
        error: function (response) {
            flag = false
        }
    });
    return flag;
}

export function initialize_signup_form() {
    var cid = $('#course_id').val()
    var formCourse = $("#newCourseForm").validate({
        rules: {
            "course[discription]": {
                required: function () {
                    CKEDITOR.instances.course_discription.updateElement();
                },
                minlength: 10
            },
            "course[course_session]": {required: true, remote: { url: "/teachers/sessions/"+ cid +"/get_sessions", type: "post"}
            },
            "course[max_age]": {
                min: function () {
                    return parseInt($('#course_min_age').val());
                }
            },
            "course[max_capacity]": {
                min: function () {
                    return parseInt($('#course_min_capacity').val());
                }
            },
        },
        errorPlacement: function (error, element) {
            if (element.hasClass('mobile-number')) {
                element.closest(".iti").parent().append(error);
            }
            else if(element.hasClass('week-session')) {
                if(element.hasClass('error')){
                    $('.sessions_error').html(element.val() +' '+ error[0].textContent);
                }
            }
            else {
                element.closest("div").append(error);
            }

    },
        messages: {
            'course[course_session]': {
                required: "Course Session field is required.",
                remote: "meeting time required."
            }
        }
    });
}
