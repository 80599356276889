$(document).on('turbolinks:load', function () {
    $(".service-book").on("click", function(e){
        if($('#cart_booking_date').val() == ''){
            $(".date-error").html('Please select date to buy a service.');
        }
        else{
            if($('#cart_booking_start_time').length == 0 || $('#cart_booking_end_time').length == 0){
                $('#new_cart').submit()
            }
            else {
                if($('#cart_booking_start_time').val() == '' || $('#cart_booking_end_time').val() == ''){
                    $(".date-error").html('Please select time to buy a service.');
                }
                else{
                    $('#new_cart').submit()
                }
            }
        }

    });
    $(".session-book").on("click", function(e){
        if($('#cart_booking_date').val() == ''){
            $(".date-error").html('Please select date to buy a course.');
        }
        else{
            $('#new_cart').submit()
        }
    });
});
export function inline_dates(dates_array){
    if(dates_array.length === 0){
        $('.session-book').prop('disabled', true);
        $(".date-error").html('There is not any available booking dates for now')
    }
    $(".calendar-inline").flatpickr({
        dateFormat: "Y-m-d",
        // inline: true,
        enable: dates_array,
        onChange: function (selectedDates, dateStr, instance) {
            $(".date-error").html('');
            $('#cart_booking_date').val(dateStr);
            find_times(dateStr)
        }
    });
}
function find_times(date){
    $.ajax({
        url: '/courses/find_time_by_date',
        type: 'post',
        dataType: "script",
        async: false,
        data: {id: $('#cart_course_id').val(), select_date: date},
        success: function (response) {

        },
        error: function (response) {

        }
    });
}
