export function init_filepond_single() {
    $(".filepond-single").each(function () {

        if ($(this).data("name")) {
            createPoster(
                this,
                $(this).data("name"),
                $(this).data("size"),
                $(this).data("type"),
                $(this).data("url"),
                $(this).data("img-value")
            );
        } else {
            FilePond.create(this, {
                name: "filepond",
                labelIdle: $(this).data("img-value") != undefined ? "<img class=\"img img-fluid\" width=\"340\" src='" + $(this).data("img-value") + "'/>" : 'Drag & Drop your picture or <span class="filepond--label-action">Browse</span>',
                onaddfile: (error, fileObj) => {
                    if ($(".submit-image").length == 1) {
                        $(".submit-image").removeClass("disabled");
                    }
                }
            });
        }
    });
}

function createPoster(input, name, size, type, url, image) {
    FilePond.create(input, {
        name: "filepond",
        imagePreviewHeight: 200,
        filePosterMaxHeight: 200,
        labelIdle: image != undefined ? "<img class=\"img img-fluid\" width=\"340\" src='" + image + "'/>" : 'Drag & Drop your picture or <span class="filepond--label-action">Browse</span>',
        files: [{
            options: {
                file: {
                    name: name,
                    size: size,
                    type: type,
                },
                metadata: {
                    poster: url,
                },
            },
        }]
    });
}

export function init_filepond_profile_pic() {

    $(".filepond-profile-pic").each(function () {
        if ($(this).data("name")) {
            createprofilePoster(
                this,
                $(this).data("name"),
                $(this).data("size"),
                $(this).data("type"),
                $(this).data("url"),
                $(this).data("img-value")
            );
        } else {
            FilePond.create(
                document.querySelector('.profile-image'),
                {
                    labelIdle: $(this).data("img-value") != undefined ? $(this).data("img-value") : 'Drag & Drop your picture or <span class="filepond--label-action">Browse</span>',
                    imagePreviewHeight: 170,
                    imageCropAspectRatio: '1:1',
                    imageResizeTargetWidth: 200,
                    imageResizeTargetHeight: 200,
                    stylePanelLayout: 'compact circle',
                    styleLoadIndicatorPosition: 'center bottom',
                    styleButtonRemoveItemPosition: 'center bottom'
                }
            );
        }
    });
}

function createprofilePoster(input, name, size, type, url, image) {
    FilePond.create(input, {
        name: "filepond",
        imagePreviewHeight: 170,
        labelIdle: image != undefined ? "<img class=\"img img-fluid\" width=\"340\" src='" + image + "'/>" : 'Drag & Drop your picture or <span class="filepond--label-action">Browse</span>',
        imageCropAspectRatio: '1:1',
        imageResizeTargetWidth: 200,
        imageResizeTargetHeight: 200,
        stylePanelLayout: 'compact circle',
        styleLoadIndicatorPosition: 'center bottom',
        styleButtonRemoveItemPosition: 'center bottom',
        files: [{
            options: {
                file: {
                    name: name,
                    size: size,
                    type: type
                },
                metadata: {
                    poster: url
                }
            }
        }]
    });
}

export function init_filepond_multiple() {
    $(".filepound-multiple").each(function () {
        createMultiplePoster(
            this,
            $(this).data("files"),
            $(this).data("remove-url"),
            $(this).data("img-value")
        );
    });
}

function createMultiplePoster(input, files, delete_url, image) {
    FilePond.create(input, {
        name: "filepond",
        files: files,
        labelIdle: image != undefined ? "<img class=\"img img-fluid\" width=\"340\" src='" + image + "'/>" : 'Drag & Drop your picture or <span class="filepond--label-action">Browse</span>',
        imagePreviewHeight: 100,
        filePosterMaxHeight: 100
    });

    $(".filepound-multiple").on("FilePond:removefile", function (e) {
        var id = e.detail.file.file["id"];
        var img_id = e.detail.file.file["img_id"];
        if (id) {
            $.ajax({
                url: delete_url,
                type: "POST",
                datatype: "Script",
                data: {img_id: img_id, id: id},
                success: function (result) {
                }
            });
        }
    });
}

export function init_filepond_media() {
    $(".filepond-media").each(function () {
        if ($(this).data("name")) {
            createmediaPoster(
                this,
                $(this).data("name"),
                $(this).data("size"),
                $(this).data("type"),
                $(this).data("url"),
                $(this).data("remove-url"),
                $(this).data("id"),
                $(this).data("img-value")
            );
        } else {
            FilePond.create(this, {
                name: "filepond",
                labelIdle: $(this).data("img-value") != undefined ? "<img class=\"img img-fluid\" width=\"340\" src='" + $(this).data("img-value") + "'/>" : 'Drag & Drop your picture or <span class="filepond--label-action">Browse</span>',
                onaddfile: (error, fileObj) => {
                    if ($(".submit-image").length == 1) {
                        $(".submit-image").removeClass("disabled");
                    }
                }
            });
        }
    });
}

function createmediaPoster(input, name, size, type, url, delete_url, item_id, image) {

    FilePond.create(input, {
        name: "filepond",
        maxFileSize: '250MB',
        mediaPreviewHeight: 100,
        filePosterMaxHeight: 100,
        labelIdle: image != undefined ? "<img class=\"img img-fluid\" width=\"340\" src='" + image + "'/>" : 'Drag & Drop your picture or <span class="filepond--label-action">Browse</span>',
        files: [
            {
                // source: 'source',
                options: {
                    // type: 'local',
                    // Mock file information
                    file: {
                        name: name,
                        size: size,
                        type: type,
                        url: url,
                        mock: true, // Should be set to true to avoid full file preloading before showing preview
                    },
                    metadata: {
                        poster: url,
                    },
                }
            }
        ]
    });
    $(".filepond-media").on("FilePond:removefile", function (e) {
        var id = item_id;
        if (id) {
            $.ajax({
                url: delete_url,
                type: "POST",
                datatype: "Script",
                data: {id: id},
                success: function (result) {
                }
            });
        }
    });
}