document.addEventListener("turbolinks:load", function () {

    jQuery(function () {
        var show_error, stripeCardResponseHandler;
        var $cardform = $('#card-form');

        $("input[data-stripe='number']").payment('formatCardNumber');
        $("input[data-stripe='exp-date']").payment('formatCardExpiry');
        $("input[data-stripe='cvc']").payment('formatCardCVC');
        $("#card-submit").on("click", function (event) {
            $cardform.find('.btn-card').prop('disabled', true).html("<i class='fa fa-spinner fa-spin'></i> Pay Now...");

            Stripe.card.createToken({
                number: $('#card_number').val(),
                cvc: $('#card_cvc').val(),
                exp_month: parseInt($('#exp_date').val().split("/")[0]),
                exp_year: parseInt($('#exp_date').val().split("/")[1]),
                name: $('#card_user_name').val()
            }, stripeCardResponseHandler);
            return false;
        });

        stripeCardResponseHandler = function (status, response) {


            if (response.error) {
                $cardform.find('.payment-errors').addClass("alert alert-secondary mb-5");
                $cardform.find('.payment-errors').text(response.error.message);
                $cardform.find('.btn-card').prop('disabled', false).html("Add Card");
            } else {
                var token = response.id;
                $cardform.append($('<input type="hidden" name="stripeToken" />').val(token));
                $cardform.append($('<input type="hidden" name="learner_details" />').val($('#details').val()));
                $cardform.get(0).submit();
            }
        };
    });


    $(function () {
        var $bankform = $('#bank-form');
        $("#bank-submit").on("click", function (event) {
            // Clear any errors
            if ($bankform.valid()) {

                $bankform.find('.has-error').removeClass('has-error');

                // Disable the submit button to prevent repeated clicks:
                // $bankform.find('.submit').prop('disabled', true).html("<i class='fa fa-spinner fa-spin'></i> Creating account...");
                $bankform.find('.btn-bank').prop('disabled', true).html("<i class='fa fa-spinner fa-spin'></i> Creating account...");

                // Request a token from Stripe:
                Stripe.bankAccount.createToken($bankform, stripeResponseHandler);

                // Prevent the form from being submitted:
                return false;
            }
        });
    });

    function stripeResponseHandler(status, response) {
        var $bankform = $('#bank-form');
        if (response.error) {
            // Show the errors on the form
            $bankform.find('.errors').text(response.error.message).addClass('text-danger');
            $bankform.find('.' + response.error.param).parent('.form-group').addClass('has-error');
            $bankform.find('button').prop('disabled', false).text('submit'); // Re-enable submission
        }
        else { // Token was created!
            $bankform.find('.submit').html("<i class='fa fa-check'></i> Account added");

            // Get the token ID:
            var token = response.id;

            // Insert the token and name into the form so it gets submitted to the server:
            $bankform.append($('<input type="hidden" name="stripeToken" />').val(token));

            // Submit the form:
            $bankform.get(0).submit();
        }
    }

});
