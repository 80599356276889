import consumer from './consumer'

consumer.subscriptions.create({channel: "NotificationsChannel"}, {
    connected: function () {
    },
    disconnected: function () {
    },

    received: function (data) {
        set_notification(data);
        return "";
    },

    notify: function (notification) {
        return alert(notification);
    },

    speak: function (notification) {
        return this.perform('speak', {
            notification: notification
        });
    }
});

function set_notification(data){
    $.ajax({
        url: "/activities/notification",
        type: "GET",
        data: { user_id: data.user_id, id: data.id},
        dataType: "script"
    });
    $(".noti-icon").removeClass('d-none');
}