$(document).on('turbolinks:load', function () {
    new List('course-category', {valueNames: ['category_name']});
    new List('course-teacher', {valueNames: ['teacher_name']});
});

$(document).on('change', '.category-item, .teacher-item, .sorting-course', function () {
    search_form()
});

export function search_form() {
    $("body").addClass('white-overlay');
    $.ajax({
        url: '/courses',
        type: 'GET',
        dataType: 'script',
        data: $("#filter_form").serializeArray(),
        success: function (result) {}
    });
}
