// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("jquery");
require("bootstrap");
require("popper.js");
require("../theme/custom");
require("moment");
require("sortablejs");
require("flatpickr");
require("jquery-validation");
require("@nathanvda/cocoon");
require("../theme/jquery.payment");
require("../theme/payment_handler");
require("../theme/slick.min");
require("../theme/step-form");
require("../theme/course_form");
require("../theme/service_form");
require("../theme/course_serach");
require("../theme/teacher_course_serach");
require("@fancyapps/fancybox");
require("../theme/cart");
require("../theme/calendar");
require("../theme/auto_scroll_msg");
require("../admin/jquery.dataTables.min");
require("../theme/common");
require("../theme/image_uploader");
require("../admin/sidebar-nav");

import 'select2'
import moment from "moment";
window.moment = moment;

import Swal from 'sweetalert2'
window.Swal = Swal

import intlTelInput from "intl-tel-input";
window.intlTelInput = intlTelInput;

import * as FilePond from "filepond";
window.FilePond = FilePond;

import FilePondPluginFileEncode from "filepond-plugin-file-encode";
window.FilePondPluginFileEncode = FilePondPluginFileEncode;

import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
window.FilePondPluginFileValidateType = FilePondPluginFileValidateType;

import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
window.FilePondPluginPdfPreview = FilePondPluginPdfPreview;

import FilePondPluginImagePreview from "filepond-plugin-image-preview";
window.FilePondPluginImagePreview = FilePondPluginImagePreview;

import FilePondPluginFilePoster from "filepond-plugin-file-poster";
window.FilePondPluginFilePoster = FilePondPluginFilePoster;

import FilePondPluginImageCrop from "filepond-plugin-image-crop";
window.FilePondPluginImageCrop = FilePondPluginImageCrop;

import FilePondPluginMediaPreview from 'filepond-plugin-media-preview';
window.FilePondPluginMediaPreview = FilePondPluginMediaPreview;

import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
window.FilePondPluginFileValidateSize = FilePondPluginFileValidateSize;

import Quill from 'quill'
window.Quill = Quill;

FilePond.registerPlugin(
    FilePondPluginFileEncode,
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
    FilePondPluginFilePoster,
    FilePondPluginImageCrop,
    FilePondPluginPdfPreview,
    FilePondPluginMediaPreview,
    FilePondPluginFileValidateSize
);

global.$ = jQuery;
global.toastr = require("toastr");

import List from 'list.js'
window.List = List;

import 'flatpickr';
import "../stylesheets/application.scss";
import { customSharePopup, setEvent } from "../theme/custom";
import { inline_dates } from "../theme/cart";
import { initialize_signup_form } from "../theme/course_form";
import { getCalendar } from "../theme/calendar";
import { init_filepond_single, init_filepond_multiple, init_filepond_profile_pic, init_filepond_media } from '../theme/image_uploader'
import { setTime, get_sub_category } from '../theme/common'
window.inline_dates = inline_dates;
window.customSharePopup = customSharePopup;
window.initialize_signup_form = initialize_signup_form;
window.setEvent = setEvent;
window.getCalendar = getCalendar;
window.init_filepond_single = init_filepond_single;
window.init_filepond_multiple = init_filepond_multiple;
window.init_filepond_profile_pic = init_filepond_profile_pic;
window.init_filepond_media = init_filepond_media;
window.setTime = setTime;
window.get_sub_category = get_sub_category;
